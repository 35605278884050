@import "../variables.scss";

.public_view_profile_header {
  padding: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_left {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_avatar {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      object-fit: cover;
      margin-right: 6px;
    }

    &_online {
      margin-right: 6px;
      width: 6px;
        height: 6px;
    }

    &_name {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #202424;
    }
  }

  &_right {
    display: flex;
    align-items: center;

    &_img {
      margin-right: 5px;
    }

    &_p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      color: #202424;
    }
  }
}

.profile_basis {
  display: flex;
  justify-content: center;


  &_content {
    width: 1150px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    min-height: calc(100vh - 400px);
  }

  &_module_choice {
    width: 22%;
  }

  &_module_choice_ul {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &_module_choice_li {
    display: flex;
    padding: 20px 0 20px 10px;
    border-bottom: 1px solid $darkGrey;
    align-items: center;
    box-sizing: border-box;
    color: $darkGrey;

    &:hover {
      color: $black;
    }
  }

  &_module_choice_img {
    margin-right: 10px;
  }

  &_module_choice_p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: inherit;
    text-transform: uppercase;
    transition: 0.2s;
    width: 100%;
    height: 100%;
    display: flex;

    &.active {
      color: $black;
    }
  }

  &_module_component {
    margin-top: 20px;
    width: 76%;
  }
}

@media (max-width: 576px) {
  .profile_basis {
    flex-direction: column;

    &_module_component {
      width: 100%;
    }

    &_content {
      flex-direction: column;
      width: 100vw;
      justify-content: flex-start;
      padding: 0px 15px;

    }

    &_module_choice {
      width: 100vw;
               
     
      &_ul { 
          display: flex;
          margin-top: 5px;
          overflow-x: scroll;
          width: 100%;
          padding-right: 20px;
          margin-bottom: 0px;
          margin-bottom: -10px;
          

      }

      &_li {
        display: flex;
        justify-content: center;
        padding-right: 12px;
        padding-left: 12px;
        white-space: nowrap;

        &.active {
          border-bottom: 2px solid #50A398;
        }
      }

      &_p {
        padding-top: 3px;
      }

    }
  }

  /* хром, сафари */
  .profile_basis_module_choice_ul::-webkit-scrollbar {
    width: 0;
  }
  
  /* ie 10+ */
  .profile_basis_module_choice_ul {
    -ms-overflow-style: none;
  }
  
  /* фф (свойство больше не работает, других способов тоже нет)*/
  .profile_basis_module_choice_ul {
    overflow: -moz-scrollbars-none;
  }
}

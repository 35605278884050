@import "../variables.scss";

.places {
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d9d8;
    padding-bottom: 10px;

    &_title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $black;
    }

    &_select {
      display: flex;
      align-items: center;
      justify-content: center;

      &_image {
        cursor: pointer;
      }

      &.mobile {
        display: none;
      }
    }
  }

  &_options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    &_left {
      display: flex;
      align-items: center;

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: $black;
        margin-right: 40px;

        &_span {
          font-weight: 600;
        }

      
      }
    }

    &_right {
      display: flex;
      align-items: center;

      &_delete {
        width: 40px;
        height: 40px;
        cursor: pointer;
        

        & path {
          stroke: #3c8278;
        }
      }

      &_add {
        display: flex;
        margin-right: 30px;
        cursor: pointer;

        &_image {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }

        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $darkAzure;
        }
      }
    }
  }

  &_cards {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &_card {
    margin-top: 10px;
    width: 255px;
    height: 255px;

    &:not(:nth-child(3n + 3)) {
      margin-right: 39px;
    }

    &_photo {
      border-radius: 8px 8px 0px 0px;
      width: 255px;
      height: 152px;
      object-fit: cover;

      &_wrapper {
        position: relative;
      }

      &_checkbox {
        position: absolute;
        bottom: 100px;
        left: 232px;
      }
    }

    &_info {
      border: 1px solid #d2d9d8;
      border-radius: 0px 0px 8px 8px;
      margin-top: -4px;
      padding: 8px;
      box-sizing: border-box;
      &_country {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $darkGrey;
      }

      &_title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $darkAzure;

        &_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        }
      }

      &_comm {
        display: flex;
        margin-top: 12px;

        &_likes {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }

          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_comments {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }
          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_fave {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }
          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .places {

    &_header {
      margin-bottom: 10px;
      &_title {
        white-space: nowrap;
      }
    }

    &_card {
      width: 48%;
      margin-right: 6px !important;
      height: 191px;

      &_photo {
        width: 100%;
        height: 100px;

        &_checkbox {
          bottom: 45px;
          left: 134px;
          z-index: 2;
        }

      }

      &_wrapper {
        width: 100%;
        height: 100px;
      }
    }

    &_options {
      margin-top: 0px;
      flex-direction: column-reverse;

      &_left_p.mobile {
        padding: 23px 0 5px 0px;
        margin-right: 0px;
        border-bottom: 1px solid #d2d9d8;
        width: 100%;
        text-align: right;

      }

      &_right {
        width: 100%;
        justify-content: space-between;
        transform: scale(0.9);

        &_add {
          white-space: nowrap;
          margin-left: -12px;
        }
      }

      &_left {
        width: 109%;
        justify-content: space-between;
        flex-direction: row;
        white-space: nowrap;
        transform: scale(0.9);


        &_p {
          display: none;

          &.mobile {
            display: block;

          }
        }
      }
    }

    &_cards {
      margin-top: 0;
    }

    &_header_select {
      display: none;

      &.mobile {
        display: flex;
        border-bottom: none;
      }
    }
  }
}

@import "../variables.scss";

.add_session {
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d9d8;
    padding-bottom: 10px;

    &_arrow {
      margin-right: 9px;
            cursor: pointer;
    }

    &_p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: $darkAzure;
      cursor: pointer;
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
  }

  &_left_content {
    margin-top: 28px;
    width: 66%;

    &_photo_div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &_photo_preview {
      height: 152px;
      border-radius: 8px;
      margin-bottom: 15px;
      object-fit: cover;
      width: 100%;

      &.main {
        border: 2px solid #50a398;
      }

      &_wrapper {
        position: relative;
        width: 48.5%;
      }

      &_checkbox {
        position: absolute;
        z-index: 999;
        top: 10px;
        right: 1px;
      }
    }

    &_h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $black;
      margin-bottom: 8px;

      &.margin {
        margin-top: 20px;
      }
    }

    &_date {
      width: 100%;
      height: 38px;
      background: #ffffff;
      border: 1px solid #d2d9d8;
      box-sizing: border-box;
      border-radius: 30px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      padding: 20px;
      color: #9ca3a1;
      margin-bottom: 20px;
      margin-top: 5px;
      color: black;

      &_wrapper {
        display: flex;
        flex-direction: column;
      }

      &_label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #9ca3a1;
        margin: 0 0 5px 15px;
      }
    }

    &_textarea {
      background: #ffffff;
      border: 1px solid #d2d9d8;
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      height: 86px;
      padding: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      outline: none;
      transition: 0.2s;

      &:focus {
        border: 1px solid #50a398;
        box-sizing: border-box;
        box-shadow: 1px 1px 4px #50a398;
      }
    }
  }

  &_right_content {
    margin-top: 20px;

    &_window {
      width: 255px;
      height: 98px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f4f7f6;
      border-radius: 8px;

      &.high {
        height: 122px;
      }
    }

    &_ul {
      display: flex;
      flex-direction: column;
    }

    &_li {
      display: flex;
      cursor: pointer;

      align-items: center;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &_img {
        margin-right: 10px;
      }

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: $darkAzure;
      }
    }
  }

  &_photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 500px;
  }

  &_buttons {
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 576px) {

  .sessions {
    &_options {
        margin-top: 20px;
        flex-direction: row;
    
        &_left.mobile {
          display: flex;
        }
    
        &_left_p.mobile {
          padding: 23px 0 5px 0px;
          margin-right: 0px;
          border-bottom: 1px solid #d2d9d8;
          width: 100%;
          text-align: right;
    
          &.noBorder {
            border-bottom: none;
          }
    
        }
    
        &_right {
          width: 100%;
          justify-content: space-between;
          transform: scale(0.9);
    
          &_add {
            white-space: nowrap;
            margin-left: -12px;
          }
        }
    
        &_left {
          width: 109%;
          justify-content: space-between;
          flex-direction: row;
          white-space: nowrap;
          transform: scale(0.9);
    
    
          &_p {
            display: none;
    
            &.mobile {
              display: block;
            }
    
    
          }
        }
    
        &_cards {
          margin-top: 0;
        }
    
    
      }
  }

  .add_session {

    &_buttons {
      align-self: center;
    }

    &_content {
      flex-direction: column-reverse;
    }

    &_left_content {
      width: 100%;
    }

    &_right_content {
      &_window {
        width: 100%;
        flex-direction: row;
        height: 64px;
      }

      &_ul {
        flex-direction: row;
        align-items: center;
      }

      &_li {
        margin-bottom: 0px !important;
      }
    }
  }
}

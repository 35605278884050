@import "./variables.scss";

.reg_auth {
  &_wrapper {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.445);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.15s;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &_content {
    width: 25vw;
    padding: 30px;
    background: #ffffff;
    border: 1px solid #d2d9d8;
    box-sizing: border-box;
    border-radius: 20px;
    transform: scale(0.8);
    transition: 0.2s;

    &.active {
      transform: scale(1);
    }

    &_lower_p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $darkAzure;
      margin-top: 20px;
      cursor: pointer;
    }

    &_fields {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      width: 100%;
      flex-direction: column;
    }

    &_input {
      height: 38px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #d2d9d8;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 0px 10px 0px 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      transition: 0.1s;

      &:focus {
        border: 1px solid #50a398;
        box-sizing: border-box;
        box-shadow: 1px 1px 4px #50a398;
      }
    }

    &_input_wrapper {
      width: 100%;
      margin-bottom: 20px;
    }

    &_label {
      margin-left: 15px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $darkGrey;
      margin-bottom: 5px;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;

    &_title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media (max-width: 576px) {
  .reg_auth_content.active {
    width: 90vw;
    overflow: scroll;
    margin-top: 190px;
    margin-bottom: 150px;
  }

  .reg_auth_wrapper.active {  
    overflow: scroll;
  }
}

/* Reset and base styles  */
*,
*::before,
*::after {
  padding: 0px;
  margin: 0px;
  border: none;
  box-sizing: border-box;
}

/* Links */

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

ul {
  list-style: none;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

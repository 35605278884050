@import '../variables.scss';

.main_photo {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &_menu {
    display: none;
  }

  &_content {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 30px;

    &.map_active {
      width: 48%;
    }
  }

  &_map {
    width: 52%;
    height: 1000px;
    margin-right: 0px;

    &.mobile {
      display: none;
    }

    &_hide {
      position: absolute;
      display: flex;
      align-items: center;
      width: 148px;
      height: 38px;
      background: #ffffff;
      border-radius: 10px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #202424;
      top: 20px;
      left: 20px;
      z-index: 999;
      padding: 12px;
      justify-content: center;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        transition: 0.2s;
      }

      &_img {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  &_header {
    display: flex;
    flex-direction: column;

    &_middle {
      display: none;
    }

    &_h1 {
      font-family: 'Comfortaa';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #50a398;
      margin-bottom: 25px;
    }

    &_fields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &_map {
        display: flex;
        align-items: center;
        align-self: flex-end;
        transition: 0.2s;

        &:hover {
          transform: scale(1.02);
          transition: 0.2s;
        }
        &_img {
          margin-right: 9px;
        }

        &_p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #202424;
        }
      }
    }

    &_sorts {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      border-bottom: 1px solid #d2d9d8;
      align-items: center;

      &_p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }

  &_body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;

    &.map_disabled {
      justify-content: flex-start;
      gap: 10px;

      &.places {
        gap: 0px;
      }

      &.profilesMapDeibled {
        gap: 0px;
      }
    }

    &.profiles {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    &_photo {
      width: 273px;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
      margin: 2px;

      &.blured {
        filter: blur(5px);
      }

      &_wrapper {
        position: relative;
      }

      &_layout {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 96.5%;
        display: flex;
        flex-direction: column;
        padding: 13px;
        box-sizing: border-box;
        justify-content: space-between;
        position: absolute;
        z-index: 2;
        top: 1px;
        opacity: 0;
        cursor: pointer;

        &:hover {
          opacity: 1;
          transition: 0.2s;
        }
        &_top {
          display: flex;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: white;
          align-self: flex-end;

          &_img {
            margin-right: 4px;
          }

          &_p {
            margin-right: 4px;
          }
        }

        &_low {
          &_title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #ffffff;
            margin-bottom: 3px;
          }

          &_name {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
          }
        }
      }
    }

    &_pagination {
      margin-top: 58px;
      margin-bottom: 58px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 576px) {
  .main_basis_body {
    width: 100%;
    padding: 0;
  }

  .main_photo {
    flex-direction: column;

    &_menu {
      display: block;
    }

    &_body {
      padding: 0 20px;
      margin-top: -36px;
      background-color: white;
      z-index: 3;
      height: 100%;
      overflow-y: scroll;
      position: relative;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      padding-top: 20px;

      &_photo {
        height: 100px;
        width: 100% !important;
      }

      &_pagination {
        margin: 10px 0 0 0;
      }
    }

    &_map {
      width: 100%;
      height: 230px;
      display: none;

      &.mobile {
        display: block;
      }
    }

    &_content {
      &.map_active {
        width: 100%;
      }
    }

    &_header {
      &_fields {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        color: #202424;
        text-transform: none;
      }
      &_middle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 15px;
        &_filters {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 38px;
          background: #ffffff;
          border: 1px solid #50a398;
          box-sizing: border-box;
          border-radius: 30px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #202424;
          padding: 14px 30px;
        }

        &_img {
          margin-right: 5px;
        }
      }

      &_h1 {
        padding: 0 20px;
        font-size: 22px;
      }

      &_sorts {
        display: none;

        &.mobile {
          display: block;
        }
      }

      &_fields {
        display: none;

        &.mobile {
          display: flex !important;
          justify-content: center;
        }
      }
    }
  }
}

.upperButton {
  position: fixed;
  width: 60px;
  height: 60px;
  opacity: 0.8;
  bottom: 30px;
  right: 10px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
}

@import "../variables.scss";

.favorites {
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;

    &_wrapper {
      display: flex;
    }

    &_body {
      margin-bottom: 20px;
    }

    &_title_first {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $darkGrey;
      cursor: pointer;
      padding-bottom: 6px;
      padding-top: 30px;
      border-bottom: 1px solid #d2d9d8;
      padding: 20px 10px 7px 10px;
      transition: 0.2s;
      white-space: nowrap;

      &.active {
        border-bottom: 1px solid $azure;
        color: $black;
      }
    }

    &_select {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      border-bottom: 1px solid #d2d9d8;
    }
  }
}

@media (max-width: 576px) {
  .favorites {

    &_header {
      margin-bottom: 10px;

      &_title {
        white-space: nowrap;
      }
    }

    &_options {
      margin-top: 0px;
      flex-direction: column-reverse;

      &_left_p.mobile {
        padding: 23px 0 5px 0px;
        margin-right: 0px;
        border-bottom: 1px solid #d2d9d8;
        width: 100%;
        text-align: right;

      }

      &_right {
        width: 100%;
        justify-content: space-between;
        transform: scale(0.9);

        &_add {
          white-space: nowrap;
          margin-left: -12px;
        }
      }

      &_left {
        width: 109%;
        justify-content: space-between;
        flex-direction: row-reverse;
        white-space: nowrap;
        transform: scale(0.9);


        &_p {
          display: none;

          &.mobile {
            display: block;

          }
        }
      }
    }

    &_cards {
      margin-top: 0;
    }

    &_header_select {
      display: none;

      &.mobile {
        display: flex;
        border-bottom: none;
      }
    }
  }

  .photos_options {
      &_left.favorites {
        display: none;
      }
  }
}

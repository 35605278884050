@import "../variables.scss";

.main_page {
  display: flex;
  flex-direction: column;

  &_profile_popup {
    &_wrapper {
      position: absolute;
      width: 202px;
      height: auto;
      right: 0px;
      top: 30px;
      background-color: $white;
      border: 1px solid $grey;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      pointer-events: none;
      opacity: 0;
      transform: scale(0.8);
      transition: 0.15s;
      overflow: hidden;

      &.active {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
      }
    }

    &_content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &_ul {
      width: 100%;
    }

    &_li {
      padding: 9px 6px 9px 15px;
      display: flex;
      align-items: center;
      width: 100%;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      transition: 0.2s;

      &:hover {
        background-color: #f4f7f6;
      }
    }
  }

  &_first_section {
    min-height: 100vh;
    background-image: url(../../img/mainPage/banner.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_header_container {
    display: flex;
    justify-content: center;
  }

  &_header {
    padding-top: 25px;

    &_user_group {
      position: relative;
      cursor: pointer;
    }

    &_loc_select {
      margin-right: 45px;
      align-items: center;
      position: relative;
                    cursor: pointer;

      &_header {
        display: flex;
        align-items: center;
      }

      &_popup {
        position: absolute;
        width: 244px;
        background-color: #ffffff;
        border: 1px solid #d2d9d8;
        box-sizing: border-box;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 21px 19px 9px 16px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #202424;
        top: 30px;
        z-index: 5;

        &_content {
          &_cities {
            overflow-y: scroll;
            max-height: 50vh;

            &_img {
              margin-right: 10px;
            }
            &_p {
              &_wrapper {
                display: flex;
                align-items: center;
              }
              height: 28px;
              display: flex;
              align-items: center;
              transition: 0.2s;

              &:hover {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                color: #50a398;

                transition: 0.2s;
              }

              &.active {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;

                color: #50a398;
              }
            }
          }
        }
      }
    }

    &_rows {
      display: flex;
      flex-direction: column;
      width: 1150px;
      padding: 0px 20px;
      box-sizing: border-box;
    }

    &_top_row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_popup {
        position: absolute;
        top: 37px;
        right: 0px;
        width: 270px;
        height: 190px;
        background: #ffffff;
        border: 1px solid #d2d9d8;
        box-sizing: border-box;
        padding: 16px 20px 16px 20px;
        z-index: 999;

        &_stroke {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #3c8278;
          padding: 6px 4px;
          transition: 0.2s;

          &:not(:last-child) {
            border-bottom: 1px solid #d2d9d8;
          }

          &:hover {
            font-weight: 600;
            transition: 0.2s;
          }
        }

        &_top {
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          border-bottom: 1px solid #d2d9d8;

          &_name {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;

            color: #202424;
          }

          &_avatar {
            height: 36px;
            width: 36px;
            border-radius: 8px;
            margin-right: 12px;
            object-fit: cover;
          }
        }
      }

      &_user_group {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &_avatar {
          border-radius: 4px;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }

        &_bell {
          margin-right: 20px;
          position: relative;
        }
      }

      &_left {
        display: flex;
        align-items: center;
      }

      &_right {
        display: flex;
        align-items: center;
      }

      &_hr {
        width: 100vw;
        background-color: $grey;
        height: 1px;
        align-self: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    &_bottom_row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_left {
        display: flex;
        align-items: center;

        &.mobile {
          display: none;
        }

        &__logo {
          margin-right: 10px;
        }

        &__p {
          font-family: Comfortaa;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          color: $white;
        }
      }

      &_right {
        display: flex;
        align-items: center;

        &__ul {
          display: flex;
        }

        &__li:not(:last-child) {
          margin-right: 18px;
        }

        &__li {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          color: $white;
          padding: 2px 0;
          box-sizing: border-box;
          border-bottom: 2px solid transparent;
          transition: 0.2s;

          &.themed {
            color: black;

            &:hover {
              border-bottom: 2px solid $azure;
              transition: 0.2s;
            }
          }
        }

        &__li:hover {
          border-bottom: 2px solid white;
          transition: 0.2s;
        }
      }

      &_hr {
        width: 100vw;
        background-color: $grey;
        height: 1px;
        align-self: center;
        margin-top: 20px;
      }
    }

    &_loc_p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      color: $white;
      margin-right: 5px;
      font-size: 13px;
    }

    &_loc_vector {
      margin-right: 5px;
    }

    &_theme_select {
      display: flex;
      align-items: center;
    }

    &_lang_select {
      display: flex;
      align-items: center;
      margin-right: 22px;

      &__planet {
        margin-right: 6px;
      }

      &__p {
        margin-right: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: $white;
      }
    }

    &_theme_p {
      &__left {
        margin-right: 7px;

        &.active {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: black;
        }

        &.disabled {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: $darkGrey;
        }
      }

      &__right {
        &.active {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: black;
        }

        &.disabled {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: $darkGrey;
        }
      }
    }
  }

  &_middle_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    justify-content: center;
    text-align: center;
    align-items: center;

    &__title {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      color: $lightGrey;
    }

    &__subtitle {
      margin-top: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: $lightAzure;
    }

    &__search-input {
      width: 350px;
      height: 56px;
      margin-top: 25px;
      background: #ffffff;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 30px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      padding: 0 57px 0 26px;

      &_wrapper {
        position: relative;
      }

      &__icon {
        position: absolute;
        bottom: 19px;
        right: 35px;
        cursor: pointer;
      }
    }
  }

  &_lower_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $white;
    margin-bottom: 30px;

    &_upper_p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: $lightAzure;
      margin-bottom: 5px;
    }

    &_middle_p {
      margin-bottom: 4px;
    }

    &_lower_row {
      display: flex;
      justify-content: center;
      align-items: center;

      &__image {
        margin-right: 3px;
      }
    }
  }

  &_second_section {
    &_wrapper {
      display: flex;
      height: 370px;
      background-color: $white;
      justify-content: center;
      align-items: flex-start;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 20px 20px;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;

      &_upper_row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_left {
          display: flex;
          align-items: center;

          &_h2 {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $azure;
            margin-right: 19px;
          }
          &_checkbox_wrapper {
            display: flex;
          }

          &_label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $black;
          }

          &_checkbox {
            width: 16px;
            height: 16px;
            margin-right: 9px;
            border: 1px solid #d2d9d8;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &_rigth {
          display: flex;

          & li {
            padding: 0px 10px 8px 10px;
            border-bottom: 1px solid $grey;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $darkGrey;

            &.active {
              font-weight: 600;
              font-size: 16px;
              color: $azure;
              border-bottom: 2px solid $azure;
            }
          }
        }
      }

      &_slider_wrapper {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        position: relative;
      }

      &_slider_card_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &_slider_card {
        &_image {
          width: 100px;
          height: 100px;
          border-radius: 8px;
          object-fit: cover;
        }
        &_author_name {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: $darkAzure;
          line-height: 16px;

          &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;

            &_row {
              display: flex;
              align-items: center;

              &_circle {
                margin-right: 5px;
              }
            }
          }
        }

        &_rating {
          &_wrapper {
            display: flex;
            margin-top: 8px;
            align-items: center;
          }

          &_image {
            margin-right: 5px;
          }

          &_mark {
            margin-right: 5px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_profession {
          margin-top: 3px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: $black;
        }

        &_location {
          margin-top: 2px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: $darkGrey;
        }
      }

      &_lower_table {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
                      cursor: pointer;

        &_image {
          margin-right: 5px;
        }

        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $darkAzure;
        }
      }
    }
  }

  &_third_section {
    &_wrapper {
      display: flex;
      height: 320px;
      background-color: $lightGrey;
      justify-content: center;
      align-items: flex-start;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 20px 20px;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;

      &_upper_row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_left {
          display: flex;
          align-items: center;

          &_h2 {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $azure;
            margin-right: 19px;
          }
          &_checkbox_wrapper {
            display: flex;
          }

          &_label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $black;
          }

          &_checkbox {
            width: 16px;
            height: 16px;
            margin-right: 9px;
            border: 1px solid #d2d9d8;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &_rigth {
          display: flex;

          & li {
            padding: 0px 10px 8px 10px;
            border-bottom: 1px solid $grey;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $darkGrey;

            &.active {
              font-weight: 600;
              font-size: 16px;
              color: $azure;
              border-bottom: 2px solid $azure;
            }
          }
        }
      }

      &_slider_arrow_left {
        position: absolute;
        top: 30px;
        left: -20px;
      }

      &_slider_arrow_right {
        position: absolute;
        right: -20px;
        top: 30px;
      }

      &_slider_wrapper {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        position: relative;
      }

      &_slider_card_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &_slider_card {
        &_image {
          width: 160px;
          height: 96px;
          object-fit: cover;
          border-radius: 8px;
        }
        &_author_name {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: $darkAzure;
          text-align: center;

          &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;
           

            &_row {
              display: flex;
              align-items: center;

              &_circle {
                margin-right: 5px;
              }
            }
          }
        }

        &_rating {
          &_wrapper {
            display: flex;
            margin-top: 8px;
            align-items: center;
          }

          &_image {
            margin-right: 5px;
          }

          &_count_likes {
            margin-right: 5px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_location {
          margin-top: 4px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: $darkGrey;
          text-align: center;
        }
      }

      &_lower_table {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
                      cursor: pointer;

        &_image {
          margin-right: 5px;
        }

        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $darkAzure;
        }
      }
    }
  }

  &_popular_photos_section {
    &_wrapper {
      display: flex;
      height: 320px;
      background-color: $white;
      justify-content: center;
      align-items: flex-start;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 20px 20px;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;

      &_upper_row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_left {
          display: flex;
          align-items: center;

          &_h2 {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $azure;
            margin-right: 19px;
          }
          &_checkbox_wrapper {
            display: flex;
          }

          &_label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $black;
          }

          &_checkbox {
            width: 16px;
            height: 16px;
            margin-right: 9px;
            border: 1px solid #d2d9d8;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &_rigth {
          display: flex;

          & li {
            padding: 0px 10px 8px 10px;
            border-bottom: 1px solid $grey;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $darkGrey;

            &.active {
              font-weight: 600;
              font-size: 16px;
              color: $azure;
              border-bottom: 2px solid $azure;
            }
          }
        }
      }

      &_slider_arrow_left {
        position: absolute;
        top: 50px;
        left: -20px;
      }

      &_slider_arrow_right {
        position: absolute;
        right: -20px;
        top: 50px;
      }

      &_slider_wrapper {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        position: relative;
      }

      &_slider_card_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &_slider_card {
        &_author_name {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: $darkAzure;

          &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;

            &_row {
              display: flex;
              align-items: center;

              &_circle {
                margin-right: 5px;
              }
            }
          }
        }

        &_rating {
          &_wrapper {
            display: flex;
            margin-top: 8px;
            align-items: center;
          }

          &_image {
            margin-right: 5px;
          }

          &_count_likes {
            margin-right: 5px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_location {
          margin-top: 4px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: $darkGrey;
        }
      }

      &_lower_table {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
                      cursor: pointer;

        &_image {
          margin-right: 5px;
        }

        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $darkAzure;
        }
      }
    }
  }

  &_survey_section {
    &_wrapper {
      display: flex;
      height: 340px;
      background-color: $white;
      justify-content: center;
      align-items: flex-start;
    }

     &_adv {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 30px;
          cursor: pointer;

          &_image {
            width: 545px;
            height: 245px;
            object-fit: cover;
            border-radius: 8px 8px 0px 0px ;
          }

          &_info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border: 1px solid #D2D9D8;
            box-sizing: border-box;
            border-radius: 0px 0px 8px 8px;
            padding: 15px 20px;
           
          }

            &_title {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: #202424;
            }
            
            &_link {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
            
              color: #3C8278;

              &.dis {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
        }

    &_content {
      display: flex;
      width: 1150px;
      padding: 0px 10px;
      flex-direction: row;
      box-sizing: border-box;
      justify-content: space-between;

      &_survey {

        &_table {
          width: 540px;
          height: auto;
          border: 1px solid #d2d9d8;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 28px 35px 15px 35px;

          &_title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: $darkAzure;
          }

          &_ul {
            margin-top: 20px;

            & li:not(:last-child) {
              margin: 8px 0;
            }
          }

          &_radio {
            margin-right: 9px;
          }

          &_label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $black;
          }
        }

        &_lower_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          color: $darkGrey;
          margin-top: 20px;
        }
      }

      &_upper_row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &_left {
          display: flex;
          align-items: center;

          &_h2 {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $azure;
            margin-right: 19px;
          }
        }
      }

      &_survey_wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
      }
    }
  }

  &_last_comments_section {
    &_wrapper {
      display: flex;
      height: 260px;
      background-color: $white;
      justify-content: center;
      align-items: flex-start;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 0px 10px;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;

      &_comment {
        &_wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 335px;
        }

        &_title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: $darkAzure;
        }

        &_content {
          margin-top: 11px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: $black;
           word-break: break-all;

        }

        &_lower_table {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 25px;

          &_profile_image {
            margin-right: 6px;
          }

          &_profile_online {
            margin-right: 6px;
            width: 6px;
              height: 6px;
          }

          &_profile_name {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $darkAzure;
            margin-right: 7px;
          }

          &_profile_pro {
            margin-right: 8px;
          }

          &_date {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: $darkGrey;
          }
        }
      }

      &_upper_row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_left {
          display: flex;
          align-items: center;

          &_h2 {
            font-family: Comfortaa;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $azure;
            margin-right: 19px;
          }
        }
      }

      &_comments_wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &_about_section {
    &_wrapper {
      display: flex;
      height: 325px;
      background-color: $lightGrey;
      justify-content: center;
      align-items: center;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 0px 10px;
      flex-direction: row;
      box-sizing: border-box;
      justify-content: space-between;

      &_card {
        width: 255px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_first {
          width: 255px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }

        &_row {
          display: flex;
          align-items: center;
        }

        &_camera {
          margin-right: 10px;
        }

        &_title {
          font-family: Comfortaa;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          color: $black;
        }

        &_text__1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $azure;
          margin-top: 20px;
        }

        &_text__2 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: $black;
          margin-top: 15px;
          text-align: center;

          &_first {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: $black;
            margin-top: 15px;
            text-align: left;
          }
        }
      }
    }
  }

  &_proposal_section {
    &_wrapper {
      display: flex;
      height: 420px;
      background-color: $lightGrey;
      justify-content: center;
      align-items: center;
      background-image: url(../../img/mainPage/proposalBack.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &_content {
      display: flex;
      flex-direction: column;
      width: 1150px;
      padding: 20px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;

      &_title {
        font-family: Comfortaa;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 33px;
        color: $white;
      }

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        color: $lightAzure;
      }
    }
  }

  &_footer_section {
    &_wrapper {
      display: flex;
      height: 294px;
      background-color: $darkAzure;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &_content {
      display: flex;
      width: 1150px;
      padding: 20px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: flex-start;

      &_li {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $white;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &.first {
          font-family: Comfortaa;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          color: $lightAzure;
          margin-bottom: 15px;
        }
      }

      &_contacts {
        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: $white;
        }

        &_back {
          &_wrapper {
            display: flex;
            align-items: center;
            margin-top: 25px;
          }

          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $lightAzure;
          }

          &_icon {
            margin-right: 10px;
          }
        }

        &_social {
          &_wrapper {
            margin-top: 15px;
          }

          &_img:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

  // под мобильные адаптация

@media (max-width: 576px) {

  .main_page {
    &_header {

      padding-top: 0;

      &_container {
        padding-top: 25px;
      }


      &_top_row {
         display: none;

         &_hr {
           display: none;
         }
      }

      &_bottom_row {
        padding: 0 20px;
        &_right {
          display: none;
        }
      }
    }

    &_middle_block{
      &__title {
        font-size: 32px;
        font-size: 1.7em;
      }
    }

    &_survey_section{

      &_wrapper {
        flex-direction: column;
        height: auto;
        margin-bottom: 30px;
      }

      &_adv {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 30px;

        &_link {
          width: 100%;
              margin-bottom: 50px;
        }

        &_image {
          width: 100%;
        }
      }

      &_content {
        width: 100%;
        flex-direction: column;

        &_survey_wrapper {
          width: 100%;
        }

        &_survey_table {
          width: 100%;
        }
      }
    }

    &_second_section {
      &_content {
        padding: 20px 0px 20px 20px;
      }
    }

    &_third_section {
      &_wrapper { 
        height: auto;
      }

      &_content {
        padding: 20px 0px 20px 20px;
      }

      &_content_upper_row_left_h2 {
        font-size: 21px;
      }
    }

    &_second_section_content_upper_row_left_h2 {
      font-size: 21px;
    }

    &_popular_photos_section_content_upper_row_left_h2 {
      font-size: 21px;
    }

    &_popular_photos_section_content {
      padding: 20px 0px 20px 20px;
    }

    &_proposal_section{ 
      &_content {
        &_title {
          text-align: center;
          font-size: 30px ;
        }

        &_p {
          text-align: center;
        }
      }
    }

    &_about_section {
      &_wrapper {
        display: none;
      }
    }

    &_footer_section {
      &_wrapper {
        height: 450px;
      }

      &_content {
        flex-wrap: wrap;

        &_ul {
          width: 50%;
          margin-bottom: 25px;
        }

        &_contacts {
          width: 50%;
        }
      }


    }

    &_header_bottom_row_left.mobile {
      display: block;
    }
  }

    .main_page_popular_photos_section_content_lower_table {
      justify-content: flex-start;
    }

    .main_page_third_section_content_lower_table {
      justify-content: flex-start;
    }

    .main_page_second_section_content_lower_table {
        justify-content: flex-start;
    }

        .main_page_header_bottom_row_left.mobile {
          width: 25px;
          height: 14px;
        }

        .main_page_header_theme_select {
          display: none;
        }

        .main_page_header_theme_select.mobile {
          display: flex !important;
        }
                .main_page_last_comments_section_content_comment_wrapper {
                  min-width: 300px;
                }

    .main_page_last_comments_section_content {
      width: 100%;
    }

        .main_page_last_comments_section_content_comments_wrapper {
              padding-bottom: 20px;
              overflow-x: scroll;
        }
}

@import "../variables.scss";

.reviews {
  &_comment {
    display: flex;
    flex-direction: column;
    padding-bottom: 13px;
    border-bottom: 1px solid #d2d9d8;
    margin-bottom: 20px;

    &_upper {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      justify-content: space-between;

      &_right {
        display: flex;

        &_edit {
          width: 25px;
          height: 25px;
          fill: #3c8278;
          margin-right: 10px;
          margin-top: 5px;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
            transition: 0.1s;
          }
        }

        &_delete {
          width: 30px;
          height: 30px;
          cursor: pointer;
          
  
          &:hover {
            transform: scale(1.05);
            transition: 0.1s;
          }

          & path {
            stroke: #3c8278;
          }
        }
      }

      &_left {
        display: flex;
        align-items: center;
      }

      &_avatar {
        border-radius: 8px;
        width: 32px;
        height: 32px;
        margin-right: 6px;
        object-fit: cover;
      }

      &_online {
        margin-right: 6px;
        width: 6px;
          height: 6px;
      }

      &_name {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: $darkAzure;
        margin-right: 6px;
      }

      &_pro {
        margin-right: 6px;
      }

      &_date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $darkGrey;
      }
    }

    &_lower {
      &_article {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $black;
        word-wrap: break-word;
      }
    }
  }
}

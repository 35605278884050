/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
background: #50A398;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100vh;

}

/* General sidebar styles */
.bm-menu {
background: #FFFFFF;
    padding: 22px 22px;
    font-size: 1.15em;
        z-index: 99999;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Individual item */
.bm-item {
    display: inline-block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #202424;
    margin-bottom: 20px;
    padding-bottom: 5px;
}

.bm-item.menu-item {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */
    
    text-transform: uppercase;
    
    /* black */
    
    color: #202424;
    margin-bottom: 20px;
    padding-bottom: 5px;
}


/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
//все цвета на проекте
$azure: #50a398;
$darkAzure: #3c8278;
$lightAzure: #adfaf2;
$lightGrey: #f4f7f6;
$black: #202424;
$darkGrey: #9ca3a1;
$grey: #d2d9d8;
$white: #ffffff;
$red: #d86369;
$yellow: #f2cf74;
$blue: #4ac0f2;
$green: #65c463;

@import "../variables.scss";

.edit_album {
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d9d8;
    padding-bottom: 10px;
          cursor: pointer;

    &_arrow {
      margin-right: 9px;
            cursor: pointer;
    }

    &_p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: $darkAzure;
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
  }

  &_left_content {
    margin-top: 28px;
    width: 66%;

    &_h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $black;
      margin-bottom: 8px;
    }

    &_textarea {
      background: #ffffff;
      border: 1px solid #d2d9d8;
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      height: 86px;
      padding: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      outline: none;
      transition: 0.2s;

      &:focus {
        border: 1px solid #50a398;
        box-sizing: border-box;
        box-shadow: 1px 1px 4px #50a398;
      }
    }
  }

  &_right_content {
    margin-top: 20px;

    &_window {
      width: 255px;
      height: 98px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f4f7f6;
      border-radius: 8px;
    }

    &_ul {
      display: flex;
      flex-direction: column;
    }

    &_li {
      display: flex;

      align-items: center;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &_img {
        margin-right: 10px;
      }

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: $darkAzure;
      }
    }
  }

  &_photos {
    display: flex;
    flex-wrap: wrap;
    min-height: 250px;
  }

  &_buttons {
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &_popup {
    &_wrapper {
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.445);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.15s;
      opacity: 0;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    &_content {
      width: 55vw;
    min-height: 25vh;
      max-height: 70vh;
      padding: 30px;
      background: #ffffff;
      border: 1px solid #d2d9d8;
      box-sizing: border-box;
      border-radius: 20px;
      transform: scale(0.8);
      transition: 0.2s;
      margin-bottom: 150px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-self: center;

      &.team {
        width: 40vw;
      }

      &_button {
        align-self: center;
      }

      &_header {
        display: flex;
        justify-content: space-between;

        &_title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
        }

        &_shape {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      &_info {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 97%;

        &_left {
          display: flex;

          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin-right: 20px;

            &_span {
              font-weight: bold;
            }
          }
        }
      }
      &_cards {
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 100%;
        margin-bottom: 50px;

        &.team {
          display: block;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .edit_album {
    
    &_left_content {
      width: 100%;
    }

    &_buttons {
      align-self: center;
    }

    &_popup_content {
      width: 100vw
    }
  }

  .edit_album_popup_content.team {
    width: 100vw;
  }
}

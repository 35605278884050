@import '../variables.scss';

.training {
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d9d8;
    padding-bottom: 10px;

    &_title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $black;
    }

    &_select {
      display: flex;
      align-items: center;
      justify-content: center;

      &.mobile {
        display: none;
      }
    }
  }

  &_options {
    align-items: center;
    margin-top: 30px;

    &_left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: $black;
        margin-right: 40px;

        &_span {
          font-weight: 600;
        }
      }
    }

    &_right {
      display: flex;
      align-items: center;

      &_add {
        display: flex;
        margin-right: 30px;
        cursor: pointer;

        &.teamAdd {
          margin-bottom: 20px;
        }

        &_image {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }

        &_p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $darkAzure;
        }
      }
    }
  }

  &_cards {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
  }

  &_card {
    margin-top: 10px;
    height: 394px;
    width: 255px;
    cursor: pointer;
    margin-bottom: 20px;

    &:not(:nth-child(3n + 3)) {
      margin-right: 39px;
    }

    &_profile {
      display: flex;
      margin-top: 5px;
      flex-direction: column;

      &_title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #3c8278;
        margin-bottom: 1px;
      }

      &_prof {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        align-items: center;
        margin-bottom: 1px;
        &_avatar {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          object-fit: cover;
          margin-right: 5px;
        }

        &_name {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;

          color: #202424;
        }
      }
    }

    &_photo {
      border-radius: 8px 8px 0px 0px;
      width: 255px;
      height: 152px;
      object-fit: cover;

      &_wrapper {
        position: relative;
      }

      &_checkbox {
        position: absolute;
        bottom: 100px;
        left: 232px;
      }
    }

    &_info {
      border: 1px solid #d2d9d8;
      border-radius: 0px 0px 8px 8px;
      margin-top: -4px;
      padding: 8px;
      box-sizing: border-box;
      &_country {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $darkGrey;
      }

      &_title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $darkAzure;

        &_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        }
      }

      &_comm {
        display: flex;
        margin-top: 12px;

        &_likes {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }

          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_comments {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }
          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }

        &_fave {
          display: flex;
          margin-right: 11px;

          &_image {
            margin-right: 5px;
          }
          &_p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $black;
          }
        }
      }
    }
    &_properties {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 11px;
      margin-bottom: 10px;

      &_column {
        display: flex;
        flex-direction: column;

        &_left {
          width: 130px;
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          &_text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #202424;
          }
          &_date {
            width: 18px;
            margin-right: 6px;
          }

          &_type {
            width: 18px;
            margin-right: 6px;
          }
        }
        &_right {
          display: flex;
          flex-direction: row;
              margin-bottom: 5px;
          &_text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #202424;
          }

          &_price {
            width: 17px;
            margin-right: 5px;
          }

          &_members {
            width: 17px;
            margin-right: 5px;
          }
        }
      }
    }
    &_manager {
      display: flex;
      flex-direction: column;

      &_title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #3c8278;
        margin-bottom: 1px;
      }
      &_account {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        &_count {
          color: #9CA3A1;
          font-size: 12px;
          margin-left: 5px;
        }

        &_avatar {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          object-fit: cover;
          margin-right: 5px;
        }

        &_name {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;

          color: #202424;
        }
      }
    }

    &.map_active:not(:nth-child(2n + 2)) {
      margin-right: 15px;
    }

    &.map_active:nth-child(2n + 2) {
      margin-right: 0;
    }

    &.map_disabled:not(:nth-child(4n + 4)) {
      margin-right: 26px !important;
    }

    &.map_disabled:nth-child(4n + 4) {
      margin-right: 0px !important;
    }

    &.map_disabled {
      margin-bottom: 26px !important;
    }
  }
}

@media (max-width: 576px) {

  .training {
    &_options {
      margin-top: 0px;
      flex-direction: column-reverse;

      &_left_p.mobile {
        padding: 23px 0 5px 0px;
        margin-right: 0px;
        border-bottom: 1px solid #d2d9d8;
        width: 100%;
        text-align: right;
      }

      &_right {
        width: 100%;
        justify-content: space-between;
        transform: scale(0.9);

        &_add {
          white-space: nowrap;
          margin-left: -12px;
        }
      }

      &_left {
        width: 109%;
        justify-content: space-between;
        flex-direction: row-reverse;
        white-space: nowrap;
        transform: scale(0.9);

        &_p {
          display: none;

          &.mobile {
            display: block;
          }
        }
      }
    }

    &_header {
      margin-bottom: 10px;

      &_title {
        white-space: nowrap;
      }
    }

    &_card {
      height: 120px;
      width: 100%;
      margin-right: 0px !important;
      display: flex;

      &_info {
        border-radius: 0 8px 8px 0;
        margin-top: 0px;
        width: 55%;
        height: 120px;
      }

      &.map_active {
        display: flex;
      }

      &_photo {
        object-fit: cover;

        &_checkbox {
    bottom: 95px;
      left: 99px;
        }

        &_wrapper {
          width: 45%;
          height: 120px;
        }

        width: 100%;
        height: 120px;
        border-radius: 8px 0 0 8px;
      }
    }

    &_header_select {
      display: none;

      &.mobile {
        display: flex;
        border-bottom: none;
      }
    }
  }

     .training_card {
       height: 153px;
       width: 100%;

       &_profile {
        display: none;
       }
  
       &_photo_wrapper {
         width: 45%;
         height: 153px;
       }
  
       &_photo {
         object-fit: cover;
         width: 100%;
         height: 153px;
         border-radius: 8px 0 0 8px;
       }
  
       &_info {
         width: 79%;
         height: 153px;
       }
  
       &_properties {
         display: flex;
         flex-direction: row;
         margin-top: 11px;
         margin-bottom: 20px;
         margin-left: 17px;
         flex-wrap: nowrap;
  
         &_column {
           display: flex;
           flex-direction: column;
           transform: scale(0.8);
           margin-left: -30px;
         }
       }
  
  
     }
}

.training_view_content_right {
  &_categories {
    display: flex;
    margin-bottom: 5px;
  }

  &_team_block {
    display: flex;
    width: 250px;
    justify-content: space-between;
    margin-bottom: 20px;

    &_avatar_wrapper {
      display: flex;
    }

    &_left {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: flex-start;

      &_upper {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }

      &_lower {

        &_cat {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
          }
      }
    }

    &_right {
      display: flex;
      align-items: center;
    }

    &_avatar {
      object-fit: cover;
      width: 32px;
      height: 32px;
      margin-right: 6px;
      border-radius: 8px;
    }

    &_online {
      width: 4px;
      height: 4px;
      margin-right: 6px;
    }

    &_name {
      font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #50A398;
        margin-right: 7px;
        overflow-x: hidden;
    }

    &_pro {
      width: 35px;
      height: 18px;
    }

    &_delete {
      width: 18px;
      height: 18.75px;
      cursor: pointer;
    }
  }

}

:root[data-theme="light"] {
    --background-color: #fafafa;
    --secondary-text-color: #000000;
    --arrows-back: #ffffff;
    --arrows-hover: #ffffff;
    --arrows-border:  1px solid #D2D9D8;
    --background-color-2: #F4F7F6;
}

:root[data-theme="dark"] {
    --background-color: #202424;
    --secondary-text-color: #FFFFFF;
    --arrows-back: #202424;
    --arrows-hover: #2a3030;
    --arrows-border:  1px solid #5D6565;
    --background-color-2: #353A3A;
}

html {
    background-color: var(--background-color);
    transition: 0.3s;
}

.main_page_second_section_wrapper {
    background: var(--background-color);
    transition: 0.3s;
}

.main_page_second_section_content_slider_card_rating_mark {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_second_section_content_slider_card_profession {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.rec.rec-arrow {
    background-color: var(--arrows-back);
    border: var(--arrows-border);
    transition: 0.3s;
   
}

.rec.rec-arrow:hover {
    background-color: var(--arrows-hover) !important;
    transition: 0.3s;
    
}

.rec.rec-arrow:focus {
    background-color: var(--arrows-back) !important;
    transition: 0.3s;
}

.main_page_middle_block__search-input {
    background-color: var(--background-color) ;
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_page_third_section_wrapper {
    background-color: var(--background-color-2);
    transition: 0.3s;
}

.main_page_third_section_content_slider_card_rating_count_likes {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_survey_section_content_survey_table {
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_page_survey_section_content_survey_table_label {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_last_comments_section_wrapper {
    background-color: var(--background-color);
    transition: 0.3s;
}

.main_page_last_comments_section_content_comment_content {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_about_section_wrapper {
    background-color: var(--background-color-2);
    transition: 0.3s;
}

.main_page_about_section_content_card_title {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_about_section_content_card_text__2_first {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_about_section_content_card_text__2 {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_survey_section_adv_title {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_popular_photos_section_wrapper {
    background-color: var(--background-color);
    transition: 0.3s;
}

.main_page_survey_section_wrapper {
    background-color: var(--background-color);
    transition: 0.3s;
}

.main_page_survey_section_adv_info {
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_page_survey_section_adv_link:hover {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_header_loc_select_popup {
    background-color: var(--background-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_page_header_loc_select_popup_content_cities_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.css-u4i9lb-MuiFormControl-root .MuiFilledInput-root {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.common_text_input {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background-color: var(--background-color) !important;
    transition: 0.3s;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    color: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.main_page_header_top_row_popup {
    background-color: var(--background-color);
        border: var(--arrows-border);
    transition: 0.3s;
    
}

.main_page_header_top_row_popup_top_name {
    color: var(--secondary-text-color);

    transition: 0.3s;
}

.reg_auth_content {
    background-color: var(--background-color);
    transition: 0.3s;
}

.reg_auth_header_title {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.reg_auth_content_input{
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.common_checkbox+label {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_page_profile_popup_wrapper {
    background-color: var(--background-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_page_profile_popup_li {
    color: var(--secondary-text-color);
  
    transition: 0.3s; }

.main_page_profile_popup_li:hover {
    background-color: var(--background-color-2);
    transition: 0.3s;
    
}

.main_basis {
    background-color: var(--background-color);
    transition: 0.3s;
    
}

.css-lqr6jm-MuiFormControl-root .MuiFilledInput-root {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.css-lqr6jm-MuiFormControl-root {
    border: var(--arrows-border) !important;
    transition: 0.3s;
}

.main_photo_header_sorts_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.css-kt9gza-MuiFormControl-root #demo-simple-select-filled {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.profile_card_info_third_category {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.profile_card_info_second_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    fill: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
    fill: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.css-1ogz18x-MuiButtonBase-root-MuiPaginationItem-root {
    color: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.profile_card {
    border-bottom: var(--arrows-border) !important;
    transition: 0.3s;
}

.main_photo_header_sorts {
    border-bottom: var(--arrows-border) !important;
    transition: 0.3s;
}

.sessions_card_info_comm_likes_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.sessions_card_profile_name {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.sessions_card_info_comm_comments_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.sessions_card_info_comm_fave_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.sessions_card_info {
    border: var(--arrows-border);
    transition: 0.3s;
}

.main_photo_header_fields_map_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
    cursor: pointer;
}

.main_photo_header_fields_map {
    cursor: pointer;
}

.main_places_header_fields_map {
    cursor: pointer;
}

.main_training_header_fields_map {
    cursor: pointer;
}

.main_profile_header_fields_map {
    cursor: pointer;
}

.main_photo_header_fields_map_img {
    cursor: pointer;
}

.main_page_header_bottom_row_left {
    height: 32px;
}

.my_profile_common_data_content_left_li {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.profile_basis_module_choice_p.active {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.profile_basis_module_choice_p:hover {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.profile_basis_module_choice_li:hover {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_header_middle_row_registered_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_header_middle_row_status_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_common_data_content_right_li {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_header_upper_row_name {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_header_upper_row_rating_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_about_content_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_stats_content_upper_key {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_stats_content_upper_value {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_stats_content_lower_key {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_stats_content_lower_value {
color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_requests_content_upper_key {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_requests_content_upper_value {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.messages_header_h1 {
color: var(--secondary-text-color);
    transition: 0.3s;
}

.messages_chat_info_low_left_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.chat_message_content {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.chat_lower_table {
    background-color: var(--background-color-2);
    transition: 0.3s;
}

.messages_chat_wrapper:hover {
    background-color: var(--background-color-2);
    transition: 0.3s;
}

.my_profile_header_middle_row_buttons_mail_p {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_header_middle_row_buttons_mail_p.notheme {
    color: #FFFFFF !important;
}

.my_profile_contacts_content_row_p.black {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.public_view_profile_header_left_name {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.public_view_profile_header_right_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photos_header_title_first.active {
    color: var(--secondary-text-color);
        transition: 0.3s;
}


.albums_header_title_first.active {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.albums_card_info_comm_count {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.albums_card_info {
    border: var(--arrows-border);
    transition: 0.3s;
}

.albums_view_lower_header_left_title {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.albums_view_lower_header_left_desc {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photos_card_info_comm_likes_p, .photos_card_info_comm_comments_p, .photos_card_info_comm_fave_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photos_card_info {
    border: var(--arrows-border);
        transition: 0.3s;
}

.photo_view_content_left_activities_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_right_geo_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
    
}
.photo_view_content_right_spec_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_left_title {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_left_description {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_left_textarea_field {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.photo_view_content_right_categories_title {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_right_categories_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.photo_view_content_right_albums_title {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.reviews_comment_lower_article {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.requests_public_preview_data_middle {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.requests_public_preview_data_lower_status_p {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.favorites_header_title_first.active {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_geolocation_popup {
    background-color: var(--background-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.my_profile_geolocation_popup_content_upper_title {
color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_geolocation_popup_content_table_left {
color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_geolocation_popup_content_table_right {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.my_profile_geolocation_popup_content_message {
    color: var(--secondary-text-color);
        transition: 0.3s;
}

.my_profile_about_textarea {
    color: var(--secondary-text-color);
    background-color: var(--background-color);
    border: var(--arrows-border);
        transition: 0.3s;
}

.reg_auth_content {
    border: var(--arrows-border);
        transition: 0.3s;
}

.chat_request_first_message_data_body_left_p {
    color: var(--secondary-text-color);
       
        transition: 0.3s;
}

.chat_request_first_message_data_body_right_p {
    color: var(--secondary-text-color);

    transition: 0.3s;
}

.messages_chat_info_request_status_p {
    color: var(--secondary-text-color);
    
        transition: 0.3s;
}

.photos_options_left_p {
    color: var(--secondary-text-color);
    
        transition: 0.3s;
}

.add_photo_left_content_textarea {
    color: var(--secondary-text-color);
        background-color: var(--background-color);
        border: var(--arrows-border);
        transition: 0.3s;
}

.css-di1djx-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    background-color: var(--background-color) !important;
    color: var(--secondary-text-color) !important;

    transition: 0.3s;
}

.css-i4bv87-MuiSvgIcon-root {
    fill: var(--secondary-text-color) !important;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    color: var(--secondary-text-color) !important;
    font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
}

.MuiOutlinedInput-notchedOutline {
    border: var(--arrows-border) !important;
}

.MuiAutocomplete-listbox {
    background-color: var(--background-color);
}

.MuiAutocomplete-option {
    color: var(--secondary-text-color) !important;
    
        transition: 0.3s;
}

.add_photo_right_content_window {
    background-color: var(--background-color-2);
}

.places_options_left_p {
    color: var(--secondary-text-color) !important;
    
        transition: 0.3s;
}

.sessions_options_left_p {
    color: var(--secondary-text-color) !important;
    
        transition: 0.3s;
}

.sessions_header_title {
    color: var(--secondary-text-color) !important;
    
        transition: 0.3s;
}

.places_header_title {
    color: var(--secondary-text-color) !important;
    
        transition: 0.3s;
}

.add_session_left_content_textarea {
    color: var(--secondary-text-color);
        background-color: var(--background-color);
        border: var(--arrows-border);
        transition: 0.3s;
}

.add_session_right_content_window {
    background-color: var(--background-color-2);
    transition: 0.3s;
}

.css-u4i9lb-MuiFormControl-root {
    border: var(--arrows-border) !important;
    transition: 0.3s;
}

.add_session_left_content_date {
    color: var(--secondary-text-color);
        background-color: var(--background-color);
        border: var(--arrows-border);
        transition: 0.3s;
}

.css-cex1ht-MuiFormControl-root {
    border: var(--arrows-border) !important;
    transition: 0.3s;
}

.css-cex1ht-MuiFormControl-root .MuiFilledInput-root {
    color: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.css-1ofa2g1-MuiFormControl-root {
    color: var(--secondary-text-color);
        transition: 0.3s;
        border: var(--arrows-border) !important;
}

.css-1ofa2g1-MuiFormControl-root .MuiFilledInput-root {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root {
    background-color: var(--background-color) !important;
    transition: 0.3s;
}

.css-ptiqhd-MuiSvgIcon-root {
    fill: var(--secondary-text-color) !important;
    transition: 0.3s;
}

.common_location_input {
    color: var(--secondary-text-color);
    background-color: var(--background-color);
    border: var(--arrows-border);
    transition: 0.3s;
}

.css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    background-color: var(--background-color) !important;
    transition: 0.3s;
}

.bm-menu {
    background-color: var(--background-color);
    transition: 0.3s;
}

.bm-item.menu-item {
    color: var(--secondary-text-color);
    transition: 0.3s;
}

.main_photo_body {
    background-color: var(--background-color);
    transition: 0.3s;
}

.main_photo_header_middle_filters {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    transition: 0.3s;
}


.css-igs3ac {
    background-color: var(--background-color) !important;
    
}

.css-1jj3t9y #demo-simple-select-filled {
    color: var(--secondary-text-color) !important;
}

.css-1jj3t9y {
    border: var(--arrows-border) !important;
}

.css-177ic5c {
    background-color: var(--background-color) !important;
}

.css-1km1ehz {
    color: var(--secondary-text-color) !important;
}

.photo_view_content_right_table {
    background-color: var(--background-color-2) !important;
}

.photo_view_content_right_table_row_p {
    color: var(--secondary-text-color) !important;
}

.photo_view_content_right_table_row_p {
    color: var(--secondary-text-color) !important;
}

.css-q44vsa {
    color: var(--secondary-text-color) !important;
}

.css-c7fotr {
    border: var(--arrows-border) !important;
}

.css-lykvwm {
    color: var(--secondary-text-color) !important;
}

.css-16y9h98 {
    color: var(--secondary-text-color) !important;
}

.css-u4i9lb-MuiFormControl-root #demo-simple-select-filled {
        width: 100%;
}

.photo_view_content_right_data_p {
    color: var(--secondary-text-color) !important;
}

.reviews_comment {
    border-bottom: var(--arrows-border) !important;
}

.additional_wrapper p {
    color: var(--secondary-text-color) !important;
}

.main_page_middle_block__search-input {
    color: var(--secondary-text-color) !important;
}

.css-1oml8th {
    color: var(--secondary-text-color) !important;
}

.css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    color: var(--secondary-text-color);
}

.css-1636szt {
    color: var(--secondary-text-color);
}

.MuiOutlinedInput-root {
    background: var(--background-color) !important;
}

.main_page_footer_section_content_contacts_social_img {
    filter: brightness(160%)
}

.css-8fzd4f #demo-simple-select-filled  {
    width: 100% !important;
}

.css-8fzd4f {
    border: var(--arrows-border) !important;
}

.css-u4i9lb-MuiFormControl-root #demo-simple-select-filled {
    width: 100% !important;
}

.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    color:var(--secondary-text-color)
}

.css-1iwjfd1 .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    z-index: 2 !important;
    color: var(--secondary-text-color) !important;
}

.edit_album_left_content_textarea {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    border: var(--arrows-border);
}

.edit_album_popup_content {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
}

.albums_options_left_p {
    color: var(--secondary-text-color);
}

.add_training_left_content_textarea {
    background-color: var(--background-color);
    border: var(--arrows-border);
    color: var(--secondary-text-color);
}

.add_training_right_content_window {
    background-color: var(--background-color-2);
}

.add_training_left_content_h1 {
color: var(--secondary-text-color);
}

.add_photo_left_content_h1 {
    color: var(--secondary-text-color);
}

.add_photo_left_content_h1.margin {
    color: var(--secondary-text-color);
}

.add_session_left_content_h1 {
    color: var(--secondary-text-color);}

.team_card_info_third_category {
    color: var(--secondary-text-color);
}

.team_card {
    border-bottom: var(--arrows-border);
}

.team_header_title_first.active {
    color: var(--secondary-text-color);
}

.training_card_properties_column_left_text {
    color: var(--secondary-text-color);
}

.training_card_properties_column_right_text {
    color: var(--secondary-text-color);
}

.training_card_manager_account_name {
    color: var(--secondary-text-color);
}

.training_card_profile_prof_name.training_card_profile_prof_name {
    color: var(--secondary-text-color);
}

.training_card_info_comm_likes_p {
    color: var(--secondary-text-color);
}

.training_card_info_comm_comments_p {
    color: var(--secondary-text-color);
}

.training_card_info_comm_fave_p {
    color: var(--secondary-text-color);
}

.training_options_left_p {
    color: var(--secondary-text-color);
}

.training_view_content_right_team_single_title {
    border-bottom: var(--arrows-border);
}

.training_card_info {
    border: var(--arrows-border);
}

.training_view_content_right_table {
    background-color: var(--background-color-2);
}

.training_view_content_right_table_row_p {
    color: var(--secondary-text-color);
}

.training_view_content_right_spec_p {
    color: var(--secondary-text-color);
}

.training_view_content_right_categories_title {
    color: var(--secondary-text-color);
}

.training_view_content_left_textarea_field {
    background-color: var(--background-color);
    border: var(--arrows-border);
}

.main_training_header_sorts_p {
    color: var(--secondary-text-color);
}

.training_view_content_right_geo_p {
    color: var(--secondary-text-color);
}

.training_view_content_right_categories_p {
    color: var(--secondary-text-color);
}

.training_view_content_left_activities_p {
    color: var(--secondary-text-color);
}

.training_view_content_left_title {
    color: var(--secondary-text-color);
}

.training_view_content_left_description {
    color: var(--secondary-text-color);
}

.training_view_content_right_team_single_title {
    color: var(--secondary-text-color);
}

.training_card_manager_account_count {
    color: var(--secondary-text-color);
}

.main_training_header_fields_map_p {
    color: var(--secondary-text-color);
    cursor: pointer;
}

.main_training_header_fields_map_img {
    cursor: pointer;
}


.main_training_header_sorts {
    border-bottom: var(--arrows-border);
    
}
.messages_chat_wrapper {
    border-bottom: var(--arrows-border);
}

.main_page_header_top_row_popup_top {
    border-bottom: var(--arrows-border);
}

.main_page_header_top_row_popup_stroke:not(:last-child) {
    border-bottom: var(--arrows-border);
}

.my_profile_common_data_title {
    border-bottom: var(--arrows-border);
}

.my_profile_about_title {
    border-bottom: var(--arrows-border);
}

.my_profile_contacts_title {
    border-bottom: var(--arrows-border);
}

.my_profile_stats_title {
    border-bottom: var(--arrows-border);
}

.my_profile_requests_title {
    border-bottom: var(--arrows-border);
}

.profile_basis_module_choice_li {
border-bottom: var(--arrows-border);
    
}

.reviews_options_left_p {
    color: var(--secondary-text-color);
}

.reviews_card_main_review {
    color: var(--secondary-text-color);
}

.reviews_card {
    border-bottom: var(--arrows-border);
}

.reviews_header_title_first {
    color: var(--secondary-text-color);
}

.css-1c99szj-MuiRating-icon {
    color: var(--secondary-text-color) !important;
}

.training_header_title {
    color: var(--secondary-text-color) ;
}

.dropdown-notify-menu-content {
    background-color: var(--background-color);
}

.dropdown-notify-menu-wrapper.active {
border: var(--arrows-border);
}

.last_message_notification_wrapper {
    background-color: var(--background-color-2);
}

.last_message_notification_wrapper p {
        color: var(--secondary-text-color);

}

.dropdown_notify_menu_notification_wrapper {
    border-bottom: var(--arrows-border);
}

.notifications_counter_wrapper p {
    color: var(--secondary-text-color);
}

.dropdown_notify_menu_notification_wrapper:hover {
background-color: var(--background-color-2);
transition: 0.3s;
}

.main_training_body {
    background-color: var(--background-color);
}

.photos_rights_title {
color: var(--secondary-text-color) !important;
}

.my_profile_header_upper_row_pro_period {
    color: var(--secondary-text-color) !important;
}

.places_header {
    border-bottom: var(--arrows-border);
}

.css-zy01o1-MuiFormControl-root #demo-simple-select-filled {
    color: var(--secondary-text-color) !important;
}

.training_view_content_left_textarea_field {
    color: var(--secondary-text-color)
}

.edit_album_left_content_h1 {
    color: var(--secondary-text-color)
}

.upperButton svg {
    fill: var(--secondary-text-color)
}

.reviews_card_info {
    color: var(--secondary-text-color)
}
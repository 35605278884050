@import '../variables.scss';

.team {

  &_body {
    display: flex;
      flex-direction: column;
  }

  &_invite {
    display: flex;
    flex-direction: column;

    &_footer {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }

    &_cards {
      margin-top: 20px;
    }

    &_fields {
      &_upper {
        display: flex;
        justify-content: space-between;
      }

      &_buttons {
        display: flex;
        align-self: flex-end;
      }
    }
  }

  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 10px;

    &_wrapper {
      display: flex;
    }

    &_body {
      margin-bottom: 20px;
    }

    &_title_first {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $darkGrey;
      cursor: pointer;
      padding-bottom: 6px;
      padding-top: 30px;
      border-bottom: 1px solid #d2d9d8;
      padding: 20px 10px 7px 10px;
      transition: 0.2s;
      white-space: nowrap;

      &.active {
        border-bottom: 1px solid $azure;
        color: $black;
      }
    }

    &_select {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      border-bottom: 1px solid #d2d9d8;
    }
  }
  &_card {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d9d8;
    cursor: pointer;

    &_avatar {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-right: 15px;
      border-radius: 8px;

      &_wrapper {
        position: relative;
      }

      &_checkbox {
        position: absolute;
        top: 3px;
        right: 7px;
      }
    }

    &_info {
      display: flex;
      flex-direction: column;

      &_first {
        display: flex;
        align-items: center;
        &_online {
          margin-right: 6px;
          width: 6px;
          height: 6px;
        }

        &_name {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #50a398;
        }
      }

      &_second {
        display: flex;
        margin-top: 10px;
        &_img {
          margin-right: 6px;
        }

        &_p {
          margin-right: 12px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;

          color: #202424;
        }
      }

      &_third {
        display: flex;
        margin-top: 8px;

        &_category {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: #202424;
          margin-right: 5px;
        }

        &_specs {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #9ca3a1;
          display: flex;
          align-items: center;
        }
      }

      &_fourth {
        margin-top: 8px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #9ca3a1;

        &.delete,
        &.reccomend {
          color: $darkAzure;
        }
        &.reccomended {
          color: #202424;
        }
      }
      &_status {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        margin-top: 9px;

        &.accepted {
          width: 83px;
          color: #65c363;
          border: 2px solid #65c363;
        }

        &.pending {
          width: 155px;
          color: #f2cf74;
          border: 2px solid #f2cf74;
        }

                 &.rejected {
                   width: 100px;
                   color: #f63100;
                   border: 2px solid #f63100;
                 }
      }
    }
  }
  &_add {
    margin-bottom: 10px;
    &_header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d2d9d8;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &_arrow {
        margin-right: 9px;
        cursor: pointer;
      }

      &_p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: $darkAzure;
        cursor: pointer;
      }
      &_search {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 576px) {
  .team {

    &_invite_fields_upper {
      flex-direction: column;
      align-items: center;
    }

    &_invite_fields_buttons {
    transform: scale(0.9);
    align-self: center;
          margin-bottom: 20px;
    }

    &_header {
      margin-bottom: 10px;

      &_title {
        white-space: nowrap;
      }
    }

    &_options {
      margin-top: 0px;
      flex-direction: column-reverse;

      &_left_p.mobile {
        padding: 23px 0 5px 0px;
        margin-right: 0px;
        border-bottom: 1px solid #d2d9d8;
        width: 100%;
        text-align: right;
      }

      &_right {
        width: 100%;
        justify-content: space-between;
        transform: scale(0.9);

        &_add {
          white-space: nowrap;
          margin-left: -12px;
        }
      }

      &_left {
        width: 109%;
        justify-content: space-between;
        flex-direction: row-reverse;
        white-space: nowrap;
        transform: scale(0.9);

        &_p {
          display: none;

          &.mobile {
            display: block;
          }
        }
      }
    }

    &_cards {
      margin-top: 0;
    }

    &_header_select {
      display: none;

      &.mobile {
        display: flex;
        border-bottom: none;
      }
    }
  }

  .photos_options {
    &_left.favorites {
      display: none;
    }
  }
}

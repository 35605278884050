@import "~react-image-gallery/styles/css/image-gallery.css";

#root {
  overflow: hidden ;
}


.hidden_file_input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 40%;
}

li, input {
  cursor: pointer;
}

.rec.rec-arrow {
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  color: transparent;
  position: absolute;
  z-index: 99999;
  border: 1px solid #d2d9d8;
}

.rec.rec-arrow:hover {
  background-color: #ffffff ;
  color: transparent !important;
  outline: none !important;
}

.rec.rec-arrow:focus {
  background-color: #ffffff ;
  color: transparent !important;
  outline: none !important;
}

.rec-arrow-left {
  background-image: url("../img/commonImages/left_arrow.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  left: -10px !important;
}

.rec-arrow-right {
  background-image: url("../img/commonImages/right_arrow.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  right: -10px !important;
}

.ymaps-2-1-79-map.ymaps-2-1-79-map ymaps,
.ymaps-2-1-79-map ymaps:after,
.ymaps-2-1-79-map ymaps:before  {
border-radius: 10px;
}

.ymaps-2-1-79-balloon__close-button {
  opacity: 0  !important;
}

.ymaps-2-1-79-balloon__content {
  margin-right: 12px !important;
}
.finance {

    &_body {

        &_subscription {
            display: flex;
            margin-top: 20px;
            width: 100%;
        }

        &_plans {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
        }

        &_plan {
            width: 32%;
            height: 510px;
            border: 1px #50a398 solid;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                transform: scale(1.02);
                transition: 0.2s;
            }

            &_title {
                color: #50a398;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 5px;
                border-bottom: 1px #9ca3a1 solid;
                padding-bottom: 3px;
            }

            &_options {
                display: flex;
                flex-direction: column;
            }

            &_option {
                margin: 5px 0 0 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #50a398;
                margin-bottom: 5px;
            }
        }

        &_history {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            &_row {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}

@media (max-width: 576px) {
    .finance {

        &_body {

            &_plans {
                display: flex;
                flex-direction: column;
            }

            &_plan {
                margin-bottom: 10px;
                width: 100%;
            }

            &_subscription {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                width: 100%;
            }
        }
       
    }
}
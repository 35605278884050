.screenloader {
    &_overlay {
        width: 56%;
        height: 50%;
        background-color: white;
        pointer-events: none;
        z-index: 0;
        position: absolute;
        background-color: transparent;
    }

    &_body {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .screenloader {
            &_overlay {
                width: 100%;
                height: 50%;
                background-color: white;
                pointer-events: none;
                z-index: -1;
                position: absolute;
                background-color: transparent;
            }
    
            &_body {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
}